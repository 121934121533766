import { H1, Paragraph, Stack, YStack, Image } from '@mythical/ui'
import React from 'react'

import Section, { SectionWrapperProps } from '../Section'

export type SectionIntroLargeProps = {
  title?: string
  description?: string
} & SectionWrapperProps

// Used for page intro sections
const SectionIntroLarge = ({
  title,
  description,
  ...props
}: SectionIntroLargeProps) => {
  return (
    <Section padded gap="$2" bg="$secondary10" {...props}>
      <Stack
        display="none"
        top="$0"
        left="$0"
        zIndex="$1"
        position="absolute"
        $gtMd={{ display: 'flex' }}
      >
        <Image
          height={80}
          width={90}
          source={require('app/assets/images/serpent1.png')}
        />
      </Stack>

      <YStack
        display="none"
        top="$0"
        right="$0"
        zIndex="$1"
        position="absolute"
        $gtMd={{ display: 'flex' }}
      >
        <Image
          height={60}
          width={80}
          source={require('app/assets/images/serpent2.png')}
        />
      </YStack>

      <Image
        height={120}
        width="100%"
        style={{ height: 120, width: '100%' }}
        source={require('app/assets/images/logo--full.png')}
        resizeMode="contain"
      />
      <H1
        textAlign="center"
        color="$primary1"
        textTransform="uppercase"
        size="$8"
        fontWeight="700"
        maxWidth={768}
        mx="auto"
        $gtSm={{
          size: '$10',
          fontWeight: '700',
        }}
      >
        {title}
      </H1>
      {description && (
        <Paragraph
          size="$3"
          textAlign="center"
          maxWidth={768}
          mx="auto"
          $gtXs={{
            size: '$6',
          }}
          color="$primary10"
          selectable
        >
          {description}
        </Paragraph>
      )}
    </Section>
  )
}

export default SectionIntroLarge
